import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput } from 'react-admin';

const roleChoices = [
    {_id: "admin", label: "Admin"},
    {_id: "view", label: "View"},
];

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="email" />
            <AutocompleteInput source="role" choices={roleChoices} optionText="label" optionValue="_id"/>
        </SimpleForm>
    </Edit>
);