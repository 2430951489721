import React from 'react';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';
import { Admin, Resource, ListGuesser, fetchUtils, EditGuesser } from 'react-admin';
import hasuraDataProvider from 'ra-data-hasura';

// The following components are created when following the react-admin tutorial

// import { PostList, PostEdit, PostCreate, PostShow } from './posts';
// import { UserList } from './users';
// import Dashboard from './Dashboard';
import { serverConfig } from './settings';
import { getAuthToken } from './util';
import authProvider from './authProvider';
import { StoreList } from './components/lists/StoreList';
import { StoreEdit } from './components/forms/StoreEdit';
import { StoreCreate } from './components/forms/StoreCreate';
import { UserList } from './components/lists/UserList';
import { UserEdit } from './components/forms/UserEdit';
import { SaleList } from './components/lists/SaleList';
import { CustomLayout } from './components/shared/customLayout';

import customTheme from './theme';
import './App.css';

// const headers = {'Content-Type': 'application/json', 'authorization': `bearer ${localStorage.getItem(localAuthStateSpaceName)['token']}`};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here

  
  options.headers.set('Authorization', `Bearer ${getAuthToken()}`);
  return fetchUtils.fetchJson(url, options);
};

const config = { 
  'primaryKey': { 
      'Users': 'user_id',
  } 
};

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={hasuraDataProvider(serverConfig.BASE_URL, httpClient, config)}
    layout={CustomLayout}
    theme={customTheme} 
  >
    <Resource name='saleview' list={ListGuesser} options={{ label: "Sales" }} />
    <Resource name='Users' list={UserList} edit={UserEdit} />
    <Resource name='Stores' list={StoreList} edit={StoreEdit} create={StoreCreate}/>
    {/* <Resource name="comments" list={ListGuesser} /> */}
  </Admin>
);

export default App;
