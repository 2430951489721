import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';


export const StoreEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="store_code" />
            <TextInput source="short_name" />
            <TextInput source="store_email_id" />
            <TextInput source="state" />
            <TextInput source="agent" />
            <TextInput source="rsm" />
            <TextInput source="store_status" />
        </SimpleForm>
    </Edit>
);