import { createMuiTheme } from '@material-ui/core/styles';


export const pxToRem = px => `${px / 22.5}rem`;
export const pxToVw = px =>
  `${(100 / document.documentElement.clientWidth) * px}vw`;

export const pxToVh = px =>
  `${px / (document.documentElement.clientHeight * 0.01)}vh`;


export default createMuiTheme({
  palette: {
    type: 'dark',

    primary: {
      main: 'rgb(179,181,191)'
    },

    secondary: {
      main: '#303030'
    },

    background: {
      default: '#212121'
    }
  },

  overrides: {
    MuiLink: {
      root: {
        color: 'rgb(255, 255, 255, 0.7)'
      }
    },

   
    MuiTable: {
      root: {
        backgroundColor: '#212121',
        // marginLeft: 16,
        // marginRight: 16,
        width: '100%',
        overflow: 'auto',
        
      }
    },

    

    MuiTableBody: {
      root: {
        
        //width: '98%',
      }
    },

    MuiPaper: {
      root: {
        backgroundColor: '#303030'
      }
    }

  }
})

// export default createMuiTheme({
//   palette: {
//     type: "dark",
//     primary: {
//       main: '#1B1F38',
//       light: 'rgb(93,175,240,0.5)',
//       dark: '#252c48'
//     },

//     secondary: {
//       main: '#5DAAE0',
//       light: 'rgb(93,175,240,0.5)',
//       dark: '#5DAAE0'
//     },

//     background: {
//       default: "#1B1F38",
//       //default: 'green',
//       // paper: "'#252c48'"
//       paper: '#252c48'
//     },
//     text: {
//       primary: 'rgb(179,181,191)',
//       secondary: 'rgb(179,181,191, 0.6)',
//       disabled: 'rgb(179,181,191)',
//       hint: 'rgb(179,181,191)',
//       //hint: '#fafafa',
//       // icon: 'rgba(255, 255, 255, 0.5)'
//     },
//   // text: {
//   //   primary: '#fff',
//   //   secondary: 'rgba(255, 255, 255, 0.7)',
//   //   disabled: 'rgba(255, 255, 255, 0.5)',
//   //   hint: 'rgba(255, 255, 255, 0.5)',
//   //   icon: 'rgba(255, 255, 255, 0.5)'  
//   // }
// },
//   overrides: {
//     MuiButton: {
//       raisedSecondary: {
//         color: 'white',
//         fontWeight: 500
//       },
//     },
//   },

//   text: {
//     primary: '#fffffa6',
//     secondary: '#fffffa6',
//     disabled: '#fffffa6',
//     hint: '#fffffa6',
//     icon: 'rgba(255, 255, 255, 0.5)'
//   },
  
  
  
// });

// // export default createMuiTheme({
// //   palette: {
// //     type: "dark",
// //     primary: {
// //       main: '#1B1F38',
// //       light: 'rgb(93,175,240,0.5)',
// //       // dark: 'rgb(93,175,240,0.2)'
// //       dark: '#252c48'
// //     },

// //     text: {
// //       primary: '#FFFFFA6',
// //       secondary: '#FFFFFA6',
// //       disabled: '#FFFFFA6',
// //       hint: '#FFFFFA6',
// //     },

// //     background: {
// //       default: "#1B1F38",
// //       //default: 'green',
// //       // paper: "'#252c48'"
// //       paper: '#252c48'
// //     },

// //     button: {
// //       color: "#fff",
// //       fontFamily: "Roboto, Helvetica, Arial, sans-serif",
// //       fontWeight: 500,
// //       fontSize: "0.875rem",
// //       lineHeight: 1.75,
// //       letterSpacing: "0.02857em",
// //       textTransform: "uppercase"
// //     }

// //   }});
