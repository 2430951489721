import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { pxToVh } from '../../theme';

const DivComponent = (props) => (<div>{props.children}</div>)

export const StoreList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">

            <TextField source="store_code" />
            <TextField source="short_name" />
            <TextField source="store_email_id" />
            <TextField source="state" />
            <TextField source="agent" />
            <TextField source="rsm" />
            <TextField source="store_status" />
            
        </Datagrid>
    </List>
);