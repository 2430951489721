import { AUTH_BASE_URL, AUTH_LOGIN_URL, BASE_HEADERS, localAuthStateSpaceName } from './settings';

function jwtDecode(t) {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token);
}

const authProvider = {

    login: async ({username, password}) => {
        const response = await fetch(`${AUTH_BASE_URL}/${AUTH_LOGIN_URL}`, {
            method: 'POST',
            headers: BASE_HEADERS,
            body: JSON.stringify({
                email: username, password
            })
        });

        if (!response.ok) {
            throw response.statusText;
        }

        const responseJSON = await response.json();
        
        const authData = jwtDecode(responseJSON['token']);
        console.log(JSON.stringify(authData));
        localStorage.setItem("auth", JSON.stringify(authData));
    },

    logout: async () => {
        localStorage.removeItem(localAuthStateSpaceName);
    },

    checkAuth: async () => {
        const auth = localStorage.getItem(localAuthStateSpaceName);

        if (!auth) {
            throw "";
        }
    },

    checkError: async (error) => {
        const status = error.status;

        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            throw error;
        }
    },

    getPermissions: async () => {
        const role = localStorage.getItem(localAuthStateSpaceName)['payload']['hasura']['x-hasura-default-role'];
        if (!role) throw "No Permissions. Role not found";
    },

};

export default authProvider;