import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';


export const StoreCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="store_code" />
            <TextInput source="short_name" />
            <TextInput source="store_email_id" />
            <TextInput source="state" />
            <TextInput source="agent" />
            <TextInput source="rsm" />
            <TextInput source="store_status" />
        </SimpleForm>
    </Create>
);