import React from 'react';
import { List, Datagrid, TextField, DateField, EmailField } from 'react-admin';

export const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="user_id" label="User ID"/>
            <EmailField source="email" />
            <TextField source="role" label = "Role"/>
            <DateField source="createdAt" />
        </Datagrid>
    </List>
);